import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import GMap from "../components/GMap";
import SEO from "../components/seo";
import { P, TextBlockHeading } from "../components/typography";
import Spacer from "../components/spacer";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulStaffMembers(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            fullName
            order
            jobTitle
            bio {
              bio
            }
            portrait {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: [AUTO, WEBP]
                aspectRatio: 1
                width: 222
                quality: 100
                cropFocus: FACE
                resizingBehavior: THUMB
              )
            }
          }
        }
      }
    }
  `);

  return (
    <Layout map={false}>
      <SEO
        title="Multnomah Medical | About | Oregon's Premier Wave Therapy Clinic"
        description="At Multnomah Medical, we offer breakthrough soft-tissue therapy and sexual wellness treatments. We help patients without the need for any surgery, shots or pills."
      />
      <div className="flex justify-center text-center">
        <div className="text-center px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
          {/*<Carousel
                numInSpotlight={2}
                cycleTime={10}
              >
                <div className="h-12 w-full bg-red-500 flex-shrink-0">A</div>
                <div className="h-12 w-full bg-yellow-500 flex-shrink-0">B</div>
                <div className="h-12 w-full bg-blue-500 flex-shrink-0">C</div>
                <div className="h-12 w-full bg-purple-500 flex-shrink-0">D</div>
                <div className="h-12 w-full bg-green-500 flex-shrink-0">E</div>
                <div className="h-12 w-full bg-pink-500 flex-shrink-0">F</div>
              </Carousel>*/}
          <h1 className="text-2xl text-gray-900 font-semibold leading-snug">
            We Are Dedicated to Providing Breakthrough Soft-tissue Therapy in
            the Portland Area
          </h1>
          <Spacer />
          <P margin="mb-6">
            We are the Premier neuropathy and joint pain treatment clinic in
            Oregon specializing in acoustic wave therapy, a noninvasive,
            drug-free solution.
          </P>
          <P margin="mb-6">
            Watch this segment for a general overview of the process and details
            about our special offer.
          </P>
          {/*<iframe 
                title="Greater Portland Today Video Segment"
                src="https://player.vimeo.com/video/395329351"
                frameBorder="0" 
                className="w-full embed-vid-player"
                aria-label="Greater Portland Today Video Segment"
                allow="autoplay; fullscreen" 
                allowFullScreen
              ></iframe>*/}
          <iframe
            className="w-full embed-vid-player"
            src="https://iframe.mediadelivery.net/embed/390189/38106101-76e6-451b-9c76-858daa026930"
            title="No More Joint Pain, No More Neuropathy. Soft Tissue Treatment Video"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <P margin="mb-6">
            Our approach is medical. You will meet with one of our medical
            providers who will review your medical history, examine you and
            supervise your treatments.
          </P>

          <TextBlockHeading>Meet Our Staff</TextBlockHeading>
          <Spacer />
          <P>
            We have a team of qualified and dedicated members who are trained in
            a wide range of different soft-tissue conditions such as carpal
            tunnel, plantar fasciitis, patellar tendonitis, and more.
          </P>
          <br />
          <P margin="mb-8">
            We'll make you feel at home so you can ask any question in a
            professional yet comfortable environment.
          </P>
          <div className="flex justify-center max-width w-full flex-wrap mb-6">
            {data.allContentfulStaffMembers.edges.map((staffMember, index) => {
              const memberName = staffMember.node.fullName;
              if (memberName === "Brian King") {
                return null;
              }
              const memberLink = memberName
                .substring(0, memberName.indexOf(" "))
                .toLowerCase();

              return (
                <div
                  className="w-full sm:w-1/2 md:w-1/3 text-center px-12 pb-4 sm:p-2 pt-0"
                  key={memberLink + index}
                >
                  <Link to={`/staff#${memberLink}`}>
                    <div className="w-full sm:px-0">
                      <GatsbyImage
                        image={staffMember.node.portrait.gatsbyImageData}
                        alt={`Portrait of Multnomah Medical staff member ${memberName}`}
                      />
                    </div>
                    <div className="w-full">
                      <p className="text-lg text-gray-900 pt-2 uppercase leading-tight">
                        {staffMember.node.fullName}
                      </p>
                      <p className="text-lg text-gray-900 leading-normal pl-2 font-semibold">
                        {staffMember.node.jobTitle}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <TextBlockHeading>Give Us A Visit!</TextBlockHeading>
          <Spacer />
          <P margin="mb-6">
            Stop by our beautiful, state of the art office conveniently located
            in the Mt. Scott professional building.
          </P>
          <StaticImage
            src="../images/mtscott.jpg"
            alt="Multnomah Medical Clinic building"
            className="mx-12 mb-6"
          />
          <P margin="mb-6">
            Our location is practically on the border between Clackamas County
            and Multnomah County and is easily accessible from Clark County via
            the 205 or I-5 highways.
          </P>
          <div className="flex mt-8">
            <div className="w-full">
              <GMap />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

//const vidRef = useRef(null)
//const [mouseInIframe, setMouseInIframe] = useState(false)

// Log video interactions
/*useEffect(() => {
    const setMouseIn = () => setMouseInIframe(true)
    const setMouseOut = () => setMouseInIframe(false)
    const logVideoClick = () => {
      if (mouseInIframe === true) {
        logEvent(firebaseContext.firebase, "video_interaction")
        typeof window !== "undefined" &&
          window.gtag('event', 'video_play', { send_to: "UA-154763728-1"})
      }
    }
    if (vidRef.current === null) {
      return
    } else {
      const localVidRef = vidRef.current
      localVidRef.addEventListener("mouseenter", setMouseIn)
      localVidRef.addEventListener("mouseout", setMouseOut)
      window.addEventListener("blur", logVideoClick)
      return () => {
        localVidRef.removeEventListener("mouseenter", setMouseIn)
        localVidRef.removeEventListener("mouseout", setMouseOut)
        window.removeEventListener("blur", logVideoClick)
      }
    }
  }, [vidRef, mouseInIframe])*/
